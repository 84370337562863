<template id="printing-template">

  <div :class="[pagesize,prinonly]">
    <section v-if="$route.query.name === 'po'" class="sheet padding-10mm printonly">
      <h1>ใบสั่งซื้อสินค้า</h1>
      <p>ท่าทราย PSV</p>
      <br>
      <p><b>เลขที่ :</b> {{ poDetail.po.id }}</p>
      <p><b>วันที่รับของ :</b> {{ poDetail.po.receive_date }}</p>
      <p><b>สถานที่สั่งของ : </b>{{ poDetail.po.supplier.name }}</p>
      <p><b>ผู้เปิด PO :</b> {{ poDetail.po.created_by.first_name }} {{ poDetail.po.created_by.last_name }}</p>
      <br>
      <table>
        <thead>
        <tr>
          <th>รายการ</th>
          <th>จำนวนเที่ยว</th>
          <th>หมายเหตุ</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in poDetail.po_item" :key="item.id">
          <td style="width: 30%;padding: 6px">{{ item.name }}</td>
          <td style="text-align: center;width: 10rem">
            {{ roundDisplay(item.trip) }}
            <!--            {{ item.trip.hasOwnProperty('round') ? item.trip.round + ' เที่ยว' : 'มาส่งให้' }}-->
          </td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <v-row class="mt-4">
        <v-col class="pb-0"><b>พนักงานขับรถ</b></v-col>
        <v-col class="pb-0"><b>รถ</b></v-col>
      </v-row>

      <v-row v-for="(item,index) in poDetail.trip" :key="item.id">
        <v-col class="pb-0">{{ index + 1 }}. {{ item.driver.first_name }} {{ item.driver.last_name }}</v-col>
        <v-col class="pb-0">{{ item.vehicle.name }}
          <span>ทะเบียน {{ item.vehicle.licence }}</span>
        </v-col>
      </v-row>

      <div style="padding-top: 3cm;padding-right: 1cm;text-align: right">
        <div>_____________</div>
        <div>ลายเซ็นต์ผู้อนุมัติ</div>
      </div>
    </section>

    <section id="order_scoop_driver" v-if="$route.query.name === 'order_scoop_driver'"
             class="sheet padding-10mm printonly">
      <div>
        <h1>ใบสั่งสำหรับตักของ (สำหรับคนตัก)</h1>
        <p><b>วันที่ :</b> {{ order.order.delivery_date }}</p>
        <p v-if="order.order.scooper"><b>ชื่อ :</b> {{ order.order.scooper.first_name }}
          {{ order.order.scooper.last_name }}</p>
        <p v-else><b>ชื่อ :</b> ______________________________</p>
        <p><b>หมายเลขออเดอร์ : </b>{{ order.order.id_display }}</p>

        <table style="margin-top: 20px">
          <thead>
          <tr>
            <th width="72px">ลำดับที่</th>
            <th>สินค้า</th>
            <th>กอง</th>
            <th>จำนวน</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td style="text-align: center">{{ item.no }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.stock }}</td>
            <td style="text-align: center">{{ item.amount }}</td>
          </tr>
          </tbody>
        </table>

        <table v-if="order.trip.length > 0" style="margin-top: 20px">
          <thead>
          <tr>
            <th>เที่ยวรถ</th>
            <th>รถ</th>
            <th>คนขับ</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(trip,index) in order.trip" :key="trip.id">
            <td style="text-align: center">{{ index + 1 }}</td>
            <td>{{ trip.vehicle.name }} ทะเบียน {{ trip.vehicle.licence }}</td>
            <td style="text-align: center">{{ trip.driver.first_name }} {{ trip.driver.last_name }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="mt-4" style="position: absolute;width: calc(100% - 20mm)" :style="{top:haftPagePoint+'mm'}">
        <hr style="margin-bottom: 40px;">
        <h1>ใบสั่งสำหรับส่งสินค้า (สำหรับคนขับ)</h1>
        <!--        <p><b>วันที่ :</b> {{ order.order.delivery_date }}</p>-->
        <!--        <p><b>หมายเลขออเดอร์ : </b>{{ order.order.id_display }}</p>-->
        <!--        <p><b>ชื่อลูกค้า : </b>{{ order.order.customer.name }}</p>-->
        <!--        <p><b>เบอร์โทร : </b>{{ order.order.customer.tel }}</p>-->
        <!--        <p><b>ที่อยู่จัดส่ง : </b>{{ order.order.customer_address }}</p>-->

        <table id="delivery-info" class="border-none">
          <tr class="border-none">
            <td class="pa-0 border-none"><p><b>หมายเลขออเดอร์ : </b>{{ order.order.id_display }}</p></td>
            <td class="pa-0 border-none"><p><b>วันที่ :</b> {{ order.order.delivery_date }}</p></td>
          </tr>
          <tr class="border-none">
            <td class="pa-0 border-none"><p><b>ชื่อลูกค้า : </b>{{ order.order.customer.name }}</p></td>
            <td class="pa-0 border-none"><p><b>เบอร์โทร : </b>{{ order.order.customer.tel }}</p></td>
          </tr>
          <tr class="border-none">
            <td colspan="2" class="pa-0 border-none">
              <p><b>ที่อยู่จัดส่ง : </b>{{ order.order.customer_address }}</p>
            </td>
          </tr>
        </table>

        <table style="margin-top: 20px">
          <thead>
          <tr>
            <th width="72px">ลำดับที่</th>
            <th>สินค้า</th>
            <th>กอง</th>
            <th>จำนวน</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td style="text-align: center">{{ item.no }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.stock }}</td>
            <td style="text-align: center">{{ item.amount }}</td>
          </tr>
          </tbody>
        </table>

        <table v-if="order.trip.length > 0" style="margin-top: 20px">
          <thead>
          <tr>
            <th>เที่ยวรถ</th>
            <th>รถ</th>
            <th>คนขับ</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(trip,index) in order.trip" :key="trip.id">
            <td style="text-align: center">{{ index + 1 }}</td>
            <td>{{ trip.vehicle.name }} ทะเบียน {{ trip.vehicle.licence }}</td>
            <td style="text-align: center">{{ trip.driver.first_name }} {{ trip.driver.last_name }}</td>
          </tr>
          </tbody>
        </table>


      </div>
      <div style="position: absolute;bottom: 7mm;right: 10mm">
        <div>______________</div>
        <div>ลายเซ็นต์ผู้รับของ</div>
      </div>
    </section>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    // item: {
    //   type: Array,
    //   default: []
    // },
    // name: {
    //   type: String,
    //   default: ""
    // },
    pagesize: {
      type: String,
      default: 'A4'
    }
  },
  data() {
    return {
      items: [],
      trips: [],
      date: '',
      scooper: '',
      order: [],
      poDetail: {}
    }
  },
  created() {
    this.$store.state.isLoading = false
    if (this.$route.query.name === 'po') {
      this.getPO()
      // this.item = JSON.parse(this.$route.query.items || '')
      // console.log('po')
      // console.log(this.item)
    }
    if (this.$route.query.name === 'order_scoop_driver') {
      this.getOrder(this.$route.query.order_id || 0)
      console.log(this.$route.query.order_id)
      // this.order = JSON.parse(this.$route.query.order || '')
      // this.items = this.formatProduct(this.order.order_item)
      // console.log(this.items)
    }


    // if (this.$route.query.name === 'quotation') {
    //   this.order = JSON.parse(this.$route.query.order)
    //   // this.date = this.$route.query.date
    //   // this.scooper = this.
    //   console.log('order')
    // }
  },
  methods: {
    getOrder(id) {
      axios.get(`/api/orders/${id}`).then(res => {
        console.log(res.data.data)
        this.order = res.data.data
        this.items = this.formatProduct(this.order.order_item)
      })
    },
    formatProduct(items) {
      let resultItems = []
      let count = 1
      items.forEach((i, index) => {
        if (i.stock_transaction === null) {
          console.log(null)
        } else if (i.stock_transaction.length === 1) {
          resultItems.push({
            no: count++,
            name: i.name,
            stock: i.stock_transaction[0].name,
            amount: i.stock_transaction[0].amount
          })
        } else if (i.stock_transaction.length > 1) {
          i.stock_transaction.forEach((s, indexT) => {
            resultItems.push({
              no: indexT === 0 ? count++ : '',
              name: indexT === 0 ? i.name : '',
              stock: s.name,
              amount: s.amount
            })
          })
        }
      })
      console.log('Log Render :', resultItems, items)
      return resultItems
    },
    getPO() {
      axios.get(`/api/stocks/po/${this.$route.query.po_id}/`)
          .then(res => {
            this.poDetail = res.data.data
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          }).finally(() => this.$store.state.isLoading = false)
    },
    roundDisplay(obj) {
      if (obj !== null) {
        return obj.round + ' เที่ยว'
      } else {
        return '(มาส่งให้)'
      }
    }
  },
  computed: {
    pageclass() {
      console.log(this.pagesize)
      return this.pagesize;
    },
    prinonly() {
      return 'printonly';
    },
    haftPagePoint() {
      if (this.pagesize === 'A4') {
        return (296 / 2) - 5
      }
      return 296 / 2
    }
  },
  mounted() {
    console.log(document.getElementById('order_scoop_driver'))
  }
}
</script>

<style scoped>

table {
  width: 100%;
  border-collapse: collapse;
}

table, td, th {
  border: 1px solid black;
}

.border-none {
  border: none;
}

td {
  padding: 5px;
}


@page {
  margin: 0
}

body {
  margin: 0
}

.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

/** Paper sizes **/
/*        body.A3           .sheet { width: 297mm; height: 419mm }
        body.A3.landscape .sheet { width: 420mm; height: 296mm }
        body.A4           .sheet { width: 210mm; height: 296mm }
        body.A4.landscape .sheet { width: 297mm; height: 209mm }
        body.A5           .sheet { width: 148mm; height: 209mm }
        body.A5.landscape .sheet { width: 210mm; height: 147mm }*/

/*.A3 .sheet {*/
/*  width: 297mm;*/
/*  height: 419mm*/
/*}*/

/*.A3.landscape .sheet {*/
/*  width: 420mm;*/
/*  height: 296mm*/
/*}*/

.A4 .sheet {
  width: 210mm;
  height: 296mm
}

.A4.landscape .sheet {
  width: 297mm;
  height: 209mm
}

.A5 .sheet {
  width: 148mm;
  height: 209mm
}

.A5.landscape .sheet {
  width: 210mm;
  height: 147mm
}

/** Padding area **/
.sheet.padding-10mm {
  padding: 10mm
}

.sheet.padding-15mm {
  padding: 15mm
}

.sheet.padding-20mm {
  padding: 20mm
}

.sheet.padding-25mm {
  padding: 25mm
}

/** For screen preview **/
@media screen {
  body {
    background: #e0e0e0
  }

  .sheet {
    background: white;
    box-shadow: 0 .5mm 2mm rgba(0, 0, 0, .3);
    margin: 5mm;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
  /*
            body.A3.landscape { width: 420mm }
            body.A3, body.A4.landscape { width: 297mm }
            body.A4, body.A5.landscape { width: 210mm }
            body.A5 { width: 148mm }*/
  .printonly {
    display: block;
  }

  .noprint {
    display: none
  }

  /*.A3.landscape {*/
  /*  width: 420mm*/
  /*}*/
  /*.A3, .A4.landscape {*/
  /*  width: 297mm*/
  /*}*/
  .A4, .A5.landscape {
    width: 210mm
  }

  .A5 {
    width: 148mm
  }


}


* {
  font-color: black;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  position: relative;
  height: auto;
  min-height: 100% !important;
}

h2,
h3,
h4,
p {
  margin: 0;
}

.header-block {
  padding: 15px;
  border-bottom: 1px dashed #ccc;
  height: 50mm;
}

.body-block {
  padding: 15px;
  height: 208mm;
}

.footer-block {
  height: 20mm;
  padding: 15px;
  border-bottom: 1px dashed #ccc;
}

.headertable {
  width: inherit;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.headertr {
  height: 35px;
}

.headertdleft {
  width: 300px;
}

.headertdright {
  width: 50%;
}


.bodytable {

}

.clear:after {
  content: '';
  display: block;
  clear: both;
}

.column2 {
  width: 50%;
  float: left;
}

.column3 {
  width: 33.33%;
  float: left;
}

.floatleft {
  float: left;
}

.floatright {
  float: left;
}

.block-address .column-title {
  float: left;
  font-weight: bold;
}

.block-address .column-text {
  float: left;
  padding-left: 10px;
}

.block-tborder table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.block-tborder table thead tr {
  background-color: #3a3a3a;
}

.block-tborder table thead tr th {
  color: #fff;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #ccc;
}

.block-tborder table tbody td {
  padding: 6px 10px;
  border: 1px solid #ccc;
}

.block-tborder table tfoot tr td {
  padding: 6px 10px;
}

.block-tborder table tfoot .border-bottom td {
  border-bottom: 1px solid #3a3a3a;
}

.block-tborder table tfoot .border-top td {
  border-top: 2px solid #3a3a3a;
}

.block-note {
  padding: 15px 0;
  border-top: 1px dashed #ccc;
  margin-top: 50px;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

/*@page {*/
/*  size: A5;*/
/*  width: 148mm;*/
/*  height: 296mm;*/
/*  margin: 0;*/
/*}*/

</style>