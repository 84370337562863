<template>
  <div>
    <!--    <div class="noprint">-->
    <!--      &lt;!&ndash;      rows/page &nbsp;<input type="number" v-model="perpage"/><br/>&ndash;&gt;-->
    <!--      &lt;!&ndash;      Items &nbsp; <input type="number" v-model="additem"/>&ndash;&gt;-->
    <!--      &lt;!&ndash;      <button @click="additems">Additem</button>&ndash;&gt;-->
    <!--      &lt;!&ndash;      <br/>&ndash;&gt;-->
    <!--      {{ items }}-->
    <!--      <v-select outlined hide-details @change="changecss" v-model="pagesize" :items="sizeOption">-->
    <!--        &lt;!&ndash;        <option disabled value="">Please select one</option>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <option>A3</option>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <option>A3 landscape</option>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <option selected>A4</option>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <option>A4 landscape</option>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <option>A5</option>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <option>A5 landscape</option>&ndash;&gt;-->
    <!--      </v-select>-->
    <!--      &lt;!&ndash;      <span>Selected: {{ pagesize }}</span><br/>&ndash;&gt;-->
    <!--      <button @click="printpage">Printing</button>-->
    <!--    </div>-->
    <div class="noprint" style="padding: 1rem">
      <v-row>
        <v-col cols="2">
          ขนาดกระดาษ
        </v-col>
        <v-col cols="2">
          <v-select outlined hide-details @change="changecss" v-model="pagesize" :items="sizeOption"></v-select>
        </v-col>
      </v-row>
      <v-btn @click="printpage" color="primary">
        <v-icon left>mdi-printer</v-icon>
        Print
      </v-btn>
    </div>
    <printing
        :items="items"
        :pagesize="pagesize"
        name="po"
    >
    </printing>
  </div>
</template>

<script>
import Printing from "@/components/Printing";

export default {
  components: {Printing},
  data() {
    return {
      pagesize: 'A4',
      additem: 0,
      maincount: 20,
      vatrate: 7,
      // vat: 23423423,
      perpage: 15,
      items: [],
      sizeOption: [{text: 'A4'}, {text: 'A4 landscape'}, {text: 'A5'}, {text: 'A5 landscape'}]
    }
  },
  created() {
    this.$store.state.isLoading = false
  },
  methods: {
    additems() {
      console.log('---items to add---', this.additem)
      let i;
      for (i = 0; i < this.additem; i++) {
        this.maincount++;
        let ox = {
          "id": this.maincount,
          "name": "iphone7Plus",
          "price": 59000,
          "storage": 128,
          "type": "iPhone",
          "qty": i + 1,
          "size": "5.5",
        };

        this.items.push(ox);
      }
    },
    calfield(item, col) {
      let digi = (col.decimal ? col.decimal : 0);
      if (item[col.field]) {
        return (digi ? item[col.field].toLocaleString('th-TH', {minimumFractionDigits: digi}) : item[col.field]);
      } else {
        return (digi ? col.func(item.qty, item.price).toLocaleString('th-TH', {minimumFractionDigits: digi}) : col.func(item.qty, item.price));
      }
    },
    calculatotal(items) {
      let total = items.reduce((o, i) => o + (i.price * i.qty), 0);
      let vat = total * 7 / 100;
      return (total + vat).toLocaleString('th-TH', {minimumFractionDigits: 2});
    },
    sumtotal(items) {
      return items.reduce((o, i) => o + (i.price * i.qty), 0).toLocaleString('th-TH', {minimumFractionDigits: 2})
    },
    calcuvat(items, v = 7) {
      let total = items.reduce((o, i) => o + (i.price * i.qty), 0);
      return (total * v / 100).toLocaleString('th-TH', {minimumFractionDigits: 2});
    },
    cssPagedMedia: (function () {
      var style = document.createElement('style');
      document.head.appendChild(style);
      return function (rule) {
        style.innerHTML = rule;
      };
    }()),
    changecss() {
      console.log('changecss')
      this.cssPagedMedia.size = (size) => {
        this.cssPagedMedia('@page {size: ' + size + '}');
      };
      this.cssPagedMedia.size(this.pagesize);
    },
    printpage() {
      window.print()
    }
  },
}
</script>

<style>

@page {
  margin: 0
}

body {
  margin: 0
}

.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

/** Paper sizes **/
/*        body.A3           .sheet { width: 297mm; height: 419mm }
        body.A3.landscape .sheet { width: 420mm; height: 296mm }
        body.A4           .sheet { width: 210mm; height: 296mm }
        body.A4.landscape .sheet { width: 297mm; height: 209mm }
        body.A5           .sheet { width: 148mm; height: 209mm }
        body.A5.landscape .sheet { width: 210mm; height: 147mm }*/

.A3 .sheet {
  width: 297mm;
  height: 419mm
}

.A3.landscape .sheet {
  width: 420mm;
  height: 296mm
}

.A4 .sheet {
  width: 210mm;
  height: 296mm
}

.A4.landscape .sheet {
  width: 297mm;
  height: 209mm
}

.A5 .sheet {
  width: 148mm;
  height: 209mm
}

.A5.landscape .sheet {
  width: 210mm;
  height: 147mm
}

/** Padding area **/
.sheet.padding-10mm {
  padding: 10mm
}

.sheet.padding-15mm {
  padding: 15mm
}

.sheet.padding-20mm {
  padding: 20mm
}

.sheet.padding-25mm {
  padding: 25mm
}

/** For screen preview **/
@media screen {
  body {
    background: #e0e0e0
  }

  .sheet {
    background: white;
    box-shadow: 0 .5mm 2mm rgba(0, 0, 0, .3);
    margin: 5mm;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
  /*
            body.A3.landscape { width: 420mm }
            body.A3, body.A4.landscape { width: 297mm }
            body.A4, body.A5.landscape { width: 210mm }
            body.A5 { width: 148mm }*/
  .printonly {
    display: block;
  }

  .noprint {
    display: none
  }

  /*.A3.landscape {*/
  /*  width: 420mm*/
  /*}*/
  /*.A3, .A4.landscape {*/
  /*  width: 297mm*/
  /*}*/
  .A4, .A5.landscape {
    width: 210mm
  }

  .A5 {
    width: 148mm
  }
}


* {
  font-color: black;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  position: relative;
  height: auto;
  min-height: 100% !important;
}

h2,
h3,
h4,
p {
  margin: 0;
}

.header-block {
  padding: 15px;
  border-bottom: 1px dashed #ccc;
  height: 50mm;
}

.body-block {
  padding: 15px;
  height: 208mm;
}

.footer-block {
  height: 20mm;
  padding: 15px;
  border-bottom: 1px dashed #ccc;
}

.headertable {
  width: inherit;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.headertr {
  height: 35px;
}

.headertdleft {
  width: 300px;
}

.headertdright {
  width: 50%;
}


.bodytable {

}

.clear:after {
  content: '';
  display: block;
  clear: both;
}

.column2 {
  width: 50%;
  float: left;
}

.column3 {
  width: 33.33%;
  float: left;
}

.floatleft {
  float: left;
}

.floatright {
  float: left;
}

.block-address .column-title {
  float: left;
  font-weight: bold;
}

.block-address .column-text {
  float: left;
  padding-left: 10px;
}

.block-tborder table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.block-tborder table thead tr {
  background-color: #3a3a3a;
}

.block-tborder table thead tr th {
  color: #fff;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #ccc;
}

.block-tborder table tbody td {
  padding: 6px 10px;
  border: 1px solid #ccc;
}

.block-tborder table tfoot tr td {
  padding: 6px 10px;
}

.block-tborder table tfoot .border-bottom td {
  border-bottom: 1px solid #3a3a3a;
}

.block-tborder table tfoot .border-top td {
  border-top: 2px solid #3a3a3a;
}

.block-note {
  padding: 15px 0;
  border-top: 1px dashed #ccc;
  margin-top: 50px;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

/*@page {*/
/*  size: A3;*/
/*  width: 145mm;*/
/*  height: 296mm;*/
/*  margin: 0;*/
/*}*/

</style>